<template>
  <v-card>
    <v-card-title>
      FG
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="tableOrderItem"
      v-model="selected"
      fixed-header
      item-key="name"
      :show-select="true"
      multi-sort
      light
      :search="search"
      :loading="loading"
      loading-text="Caricamento dati... Attendere, per favore."
      :items-per-page="5"
      class="main-table elevation-1 mt-0 theme--light"
      :item-class="itemRowBackground"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-dialog v-model="dialog" max-width="300px">
            <v-card>
              <v-card-text>
                <v-container
                  ><v-row>
                    <v-col cols="12" class="bkgrdcolor">
                      Seleziona priorità</v-col
                    ></v-row
                  >
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        v-model="editedItem.insertedPriority"
                        :items="['00', '01', '02', '03', '04', '05', '06']"
                        label="Priorità"
                        v-on:change="checkItem"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="black darken-1" text @click="close">
                  Annulla
                </v-btn>
                <v-btn
                  color="black darken-1"
                  text
                  @click="save"
                  :disabled="itemChanged"
                >
                  Conferma
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:[`header.actions`]="{ header }">
        <span style="color: #3b3b3b">Action</span>
        <v-btn
          :disabled="selected.length == 0"
          class="mr-2 actionBtn"
          @click="editItem()"
          ><v-icon small> mdi-pencil</v-icon></v-btn
        >{{ header.text }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-btn class="mr-2 actionBtn" @click="editItem(item)"
          ><v-icon small> mdi-pencil</v-icon></v-btn
        >
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "TableOrder",
  props: {
    tableOrderItem: {
      default: () => [],
      Array,
    },
    edit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      pagination: {},
      selected: [],
      dialog: false,
      loading: false,
      itemChanged: true,
      editedIndex: -1,
      editedItem: {
        text: "",
        align: "",
        value: "",
        sortable: true,
        class: "",
      },
      search: "",
      headers: [
        {
          text: "Set Priority",
          value: "insertedPriority",
          width: "15%",
        },
        {
          text: "Priority Group",
          value: "priorityGroup",
          width: "15%",
        },
        { text: "Name", value: "name", width: "60%" },
      ],
    };
  },
  methods: {
    save() {
      if (this.editedIndex > -1) {
        this.selected.forEach((el) => {
          if (el.name != this.editedItem.name) {
            if (
              this.tableOrderItem[this.editedIndex].insertedPriority !==
              this.editedItem.insertedPriority
            ) {
              el.insertedPriority = this.editedItem.insertedPriority;
              el.edit = true;
            }
          }
        });

        this.selected.forEach((el) => {
          if (el.name == this.editedItem.name) {
            if (
              this.tableOrderItem[this.editedIndex].insertedPriority !==
              this.editedItem.insertedPriority
            ) {
              el.insertedPriority = this.editedItem.insertedPriority;
              el.edit = true;
            }
          }
        });

        this.$emit("modifiedAdmin", this.tableOrderItem);
        this.close();
      }
    },
    cancel() {
      this.snackColor = "error";
      this.modalText = "Cancellato";
    },
    open() {
      this.snackColor = "info";
      this.modalText = "Modifica";
    },
    itemRowBackground: function (item) {
      var index = this.tableOrderItem.indexOf(item);
      return this.tableOrderItem[index].edit == true ? "backgroundColor" : "";
    },
    editItem(item) {
      this.itemChanged = true;
      if (item) {
        if (this.selected.indexOf(item) == -1) {
          this.selected.push(item);
        }
        this.editedIndex = this.tableOrderItem.indexOf(item);
        this.dateFormatted = item.dataCondivisione;
        this.editedItem = Object.assign({}, item);
      } else {
        this.tableOrderItem.forEach((el) => {
          if (this.selected.indexOf(el) == -1) {
            this.selected.push(el);
          }
          this.editedIndex = this.tableOrderItem.indexOf(el);
          this.editedItem = Object.assign({}, el);
        });
      }
      this.dialog = true;
    },

    checkItem() {
      if (this.editedIndex == -1) {
        this.editedIndex = 0;
      }
      if (
        this.tableOrderItem[this.editedIndex].insertedPriority ==
        this.editedItem.insertedPriority
      ) {
        this.itemChanged = true;
      } else {
        this.itemChanged = false;
      }
    },
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
    date(val) {
      this.dateFormatted = this.formatDate(val);
    },
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.date);
    },

    ...mapGetters("operationalModule", ["getActivation"]),
  },
  beforeMount() {
    if (this.edit) {
      this.headers.unshift({
        align: "center",
        value: "actions",
        sortable: false,
        class: "primary lighten-4 white--text text-top",
        icon: "mdi-pencil",
        width: 50,
      });
    }
    this.selected = [];
    this.tableOrderItem.forEach((el) => {
      el.edit = false;
    });
  },
};
</script>

<style>
.v-small-dialog__activator {
  background-color: #aeaeae;
}
.bkgrdcolor.col.col-12 {
  background-color: #fdd300;
  color: black;
  margin-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding: 5px;
}
.bkgrdcolor2.col.col-12 {
  color: red;
  margin-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding: 5px;
}
.actionBtn {
  height: 0px !important;
  min-width: 0px !important;
  padding: 0 !important;
}
</style>

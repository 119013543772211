var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-subtitle',{staticClass:"white--text text-overline blue-grey pa-2"},[_vm._v(" "+_vm._s(_vm.fgData.priorityGroup)+" "+_vm._s(_vm.fgData.name)+" ")]),_c('v-card-text',[_c('v-row',[_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium"},[_vm._v(" Stato Attivazione ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({class:_vm.activationStateValues[0]},'v-icon',attrs,false),on),[_vm._v("mdi-square-rounded")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.activationStateValues[1]))])]),_c('div',{staticClass:"text-caption text-uppercase text-center font-weight-medium"},[_vm._v(" "+_vm._s(_vm.activationStateValues[1])+" ")])],1),_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Sistemi in fault ")]),_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(_vm._s(_vm.fgData.numSystemFault)+"/"+_vm._s(_vm.fgData.numSystemTotal)+" ("+_vm._s(_vm.calculatePercentage(_vm.fgData.numSystemFault, _vm.fgData.numSystemTotal))+"%) ")])]),_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Server NT in fault ")]),(_vm.fgData.numServerNtFault != 0 && _vm.fgData.numServerNtTotal != 0)?_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(_vm._s(_vm.fgData.numServerNtFault)+"/"+_vm._s(_vm.fgData.numServerNtTotal)+" ("+_vm._s(_vm.calculatePercentage( _vm.fgData.numServerNtFault, _vm.fgData.numServerNtTotal ))+"%)")]):_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" 0%")])]),_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Server UNIX in fault ")]),(
            _vm.fgData.numServerUnixFault != 0 && _vm.fgData.numServerUnixTota != 0
          )?_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(_vm._s(_vm.fgData.numServerUnixFault)+"/"+_vm._s(_vm.fgData.numServerUnixTotal)+" ("+_vm._s(_vm.calculatePercentage( _vm.fgData.numServerUnixFault, _vm.fgData.numServerUnixTotal ))+"%)")]):_c('span',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" 0%")])]),_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" Tempo stimato ")]),_c('div',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.fgData.estimatedTime)+" giorni ")])]),_c('v-col',{staticClass:"mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1",attrs:{"cols":"2"}},[_c('div',{staticClass:"text-caption text-uppercase font-weight-medium text-center"},[_vm._v(" RTO cumulativo ")]),_c('div',{staticClass:"mx-2 font-weight-bold"},[_vm._v(" "+_vm._s(_vm.fgData.cumulativeRto)+" giorni ")])])],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"rounded":"","icon":""},on:{"click":_vm.openDetails}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-card-text-outline")])],1)]}}])},[_c('span',[_vm._v("Apri dettaglio Failover Group")])]),_c('v-tooltip',{class:_vm.statusPlan,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.statusPlan,attrs:{"rounded":"","icon":""},on:{"click":_vm.exportImpactedSystems}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-download")])],1)]}}])},[_c('span',[_vm._v("Esporta sistemi impattati")])]),_c('v-tooltip',{class:_vm.statusPlan,attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({class:_vm.statusPlan,attrs:{"rounded":"","icon":""},on:{"click":function($event){return _vm.toggleDialogEditServices(true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-pencil-outline")])],1)]}}])},[_c('span',[_vm._v("Modifica servizi")])]),_c('ModalEditServices',{attrs:{"dialogEditServicesIsOpen":_vm.dialogEditServicesIsOpen,"items":this.fgData.servicesInPlan || [],"planId":this.planId},on:{"open-dialog-edit-services":_vm.toggleDialogEditServices,"confirm-edit-services":_vm.confirmEditServices}}),_c('v-spacer'),_c('span',{class:_vm.statusPlan},[_c('span',{staticClass:"text-caption text-uppercase font-weight-medium mr-2"},[_vm._v("Avvio lavorazione:")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.fgData.startDateProcessing))])]),_vm._v("   "),_c('span',{class:_vm.statusPlan},[_c('span',{staticClass:"text-caption text-uppercase font-weight-medium mr-2"},[_vm._v("Tempo ripristino:")]),_c('span',{staticClass:"text-body-2"},[_vm._v(_vm._s(_vm.fgData.recoveryTime)+" ")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
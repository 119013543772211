<template>
  <v-dialog
    v-model="dialogOrder"
    scrollable
    width="90%"
    @click:outside="closedialogOrder()"
  >
    <v-card>
      <v-card-title>ORDINA FG</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-list two-line>
          <template>
            <v-row>
              <v-col cols="12" v-if="dialogOrder"
                ><TableFgOrder
                  :tableOrderItem="this.items"
                  @modifiedAdmin="fromChildTable"
                  :edit="edit"
                ></TableFgOrder></v-col></v-row
          ></template>
        </v-list>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="clicOnReset()"> ANNULLA </v-btn>
        <v-btn
          :disabled="fgList.length == 0"
          color="primary"
          text
          @click="clicOnSave()"
        >
          SALVA
        </v-btn>
      </v-card-actions>
      <ModalConfirm
        @open-dialog-confirm="updateDialogConfirm"
        :dialogConfirm="openDialogConfirm"
        :text="dialogText"
        v-on:confirmed="save()"
      ></ModalConfirm>
    </v-card>
  </v-dialog>
</template>

<script>
import TableFgOrder from "./core/TableFgOrder.vue";
import ModalConfirm from "./ModalConfirm.vue";
export default {
  components: { TableFgOrder, ModalConfirm },
  props: {
    dialogOrder: {
      default: false,
      type: Boolean,
    },
    items: {
      type: Array,
      required: true,
    },
    edit: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      reset: false,
      editedTableValue: [],
      dialogText: "",
      openDialogConfirm: false,
      fgList: [],
    };
  },
  computed: {},
  beforeMount() {},
  methods: {
    closedialogOrder() {
      this.$emit("open-dialog-order", false);
    },
    fromChildTable(value) {
      this.editedTableValue = value;
      var editedFg = this.editedTableValue.filter((n) => n.edit == true);
      this.fgList = [];
      editedFg.forEach((el) => {
        this.fgList.push({
          idFailovergroup: el.id,
          insertedPriority: el.insertedPriority,
        });
      });
    },
    clicOnSave() {
      this.dialogText = "Vuoi confermare le priorità inserite";

      this.updateDialogConfirm(true);
    },
    clicOnReset() {
      this.dialogText = "Vuoi chiudere le modifiche?";
      this.updateDialogConfirm(true);
    },
    updateDialogConfirm(value) {
      this.openDialogConfirm = value;
    },
    save() {
      if (this.dialogText == "Vuoi confermare le priorità inserite") {
        var bodyData = {
          idPlan: this.$route.params.planId,
          fgList: this.fgList,
        };

        this.$emit("confirm-order", bodyData);
        this.updateDialogConfirm(false);
        this.closedialogOrder();
        this.fgList = [];
      } else {
        this.updateDialogConfirm(false);
        this.closedialogOrder();
      }
    },
  },
};
</script>

<style scoped>
.v-list-item p {
  margin-bottom: 0px;
  margin-top: 8px;
}
td.text-start {
  font-size: 10px !important;
}
</style>

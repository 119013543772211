<template>
  <!-- <v-toolbar flat> -->
  <v-dialog
    v-model="dialogEditByPGOpen"
    max-width="90%"
    @click:outside="closeDialog()"
  >
    <v-card>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12" class="bkgrdcolor"> Modifica Servizi </v-col>
          </v-row>
          <v-row v-if="getActivation">
            <v-col cols="2">
              <v-select
                label="INSERITA IN PIPELINE"
                name="pipeline"
                id="pipeline"
                v-model="editedItem.inseritaInPipeline"
                :items="inseritaInPipelineItems"
                dense
                hide-details
                class="pt-2"
                v-on:change="checkItem"
              >
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                label="MAIL APP COMPLETA TO CC1"
                name="mailAppCc1"
                id="mailAppCc1"
                v-model="editedItem.mailAppCompletaToCC1"
                :items="mailAppCompletaToCC1Items"
                dense
                hide-details
                class="pt-2"
                v-on:change="checkItem"
              >
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                label="MAIL RILASCIO CC1TO AM"
                name="mailCc1toAm"
                id="mailCc1toAm"
                v-model="editedItem.mailRilascioCc1toAm"
                :items="mailRilascioCc1toAmItems"
                dense
                hide-details
                class="pt-2"
                v-on:change="checkItem"
              >
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                label="FEEDBACK AM"
                name="feddbakAm"
                id="feddbakAm"
                v-model="editedItem.feedbackAm"
                :items="feedbackAmItems"
                dense
                hide-details
                v-on:change="checkItem"
                class="pt-2"
              >
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-row>
                <v-col cols="10" style="padding-top: 7px">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.dataVerificaAm"
                        label="DATA VERIFICA AM"
                        persistent-hint
                        v-bind="attrs"
                        readonly
                        dense
                        v-on="on"
                        v-on:change="checkItem"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dataVerifica"
                      no-title
                      @input="menu1 = false"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col
                  cols="1"
                  style="padding-left: 0; margin-left: -15px; margin-top: -6px"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        rounded
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="clearDataVerificaAm()"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Cancella data</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="2" style="padding-top: 7px">
              <v-textarea
                v-model="editedItem.note"
                label="NOTE"
                dense
                hide-details
                class="pt-2"
                rows="1"
                v-on:change="checkItem"
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row v-if="getActivation">
            <v-col cols="12" class="bkgrdcolor"> Modifica Nodi </v-col>
          </v-row>
          <v-row v-if="getActivation">
            <v-col cols="4">
              <v-select
                label="RESTORE"
                name="restoreEffettuato"
                id="restoreEffettuato"
                v-model="editedItem.restoreEffettuato"
                :items="restoreEffettuatoItems"
                dense
                hide-details
                class="pt-2"
                v-on:change="checkItem"
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                label="STATO INFRA"
                name="infrStatus"
                id="infrStatus"
                v-model="editedItem.infrStatus"
                :items="infrStatusItems"
                dense
                hide-details
                class="pt-2"
                v-on:change="checkItem"
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-row>
                <v-col cols="11" style="padding-top: 7px">
                  <v-menu
                    ref="menu2"
                    v-model="menu2"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="editedItem.finishDate"
                        label="DATA COMPLETAMENTO"
                        persistent-hint
                        v-bind="attrs"
                        readonly
                        dense
                        v-on="on"
                        v-on:change="checkItem"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="finishDate"
                      no-title
                      @input="menu2 = false"
                    ></v-date-picker>
                  </v-menu>
                  <!--  -->
                </v-col>
                <v-col
                  cols="1"
                  style="padding-left: 0; margin-left: -15px; margin-top: -6px"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        rounded
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="clearDataCompletamento()"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                    <span>Cancella data</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="black darken-1" text @click="closeDialog">
          Annulla
        </v-btn>
        <v-btn
          color="black darken-1"
          text
          @click="confirmDialog"
          :disabled="!itemChanged"
        >
          Conferma
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- </v-toolbar> -->
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ModalEditByPG",
  props: {
    dialogEditByPGOpen: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      menu1: false,
      menu2: false,
      inseritaInPipelineItems: ["", "OK"],
      mailAppCompletaToCC1Items: ["", "N/A", "INVIATA"],
      mailRilascioCc1toAmItems: ["", "INVIATA"],
      feedbackAmItems: ["", "OK", "REWORK"],
      restoreEffettuatoItems: ["", "SI", "NO"],
      infrStatusItems: [
        { text: "", value: "" },
        { text: "COMPLETO", value: "Completo" },
        { text: "REWORK", value: "Rework" },
        { text: "REWORK HA", value: "Rework HA" },
        { text: "DISMESSO", value: "Dismesso" },
      ],
      dataVerifica: null,
      finishDate: null,

      itemChanged: false,

      editedItem: {},

      headers: [
        {
          align: "center",
          value: "actions",
          sortable: false,
          class: "primary lighten-4 white--text text-top",
          icon: "mdi-pencil",
          width: 50,
        },
        { text: "Nome", value: "name", width: "30%" },
        {
          text: "Inserita in pipeline",
          value: "inseritaInPipeline",
          width: "10%",
        },
        {
          text: "MAIL APP COMPLETA TO CC1",
          value: "mailAppCompletaToCC1",
          width: "10%",
        },
        {
          text: "MAIL RILASCIO CC1TO AM",
          value: "mailRilascioCc1toAm",
          width: "10%",
        },
        {
          text: "FEEDBACK AM",
          value: "feedbackAm",
          width: "10%",
        },
        {
          text: "DATA VERIFICA AM",
          value: "dataVerificaAm",
          width: "10%",
        },
        {
          text: "NOTE",
          value: "note",
          width: "10%",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),
  },
  methods: {
    parseDate(date) {
      if (!date || date === "") return null;
      return new Date(date).toLocaleDateString();
    },
    checkItem() {
      this.itemChanged = true;
    },
    clearDataVerificaAm() {
      this.editedItem.dataVerificaAm = null;
      this.dataVerifica = null;
    },
    clearDataCompletamento() {
      this.editedItem.finishDate = null;
      this.finishDate = null;
    },
    confirmDialog() {
      this.$emit("edit-PG-confirm", this.editedItem);
    },
    closeDialog() {
      this.$emit("edit-PG-cancel");
    },
  },
  watch: {
    dataVerifica(val) {
      this.editedItem.dataVerificaAm = this.parseDate(val);
      this.checkItem();
    },
    finishDate(val) {
      this.editedItem.finishDate = this.parseDate(val);
      this.checkItem();
    },
  },
};
</script>

<style>
.v-small-dialog__activator {
  background-color: #aeaeae;
}
.bkgrdcolor.col.col-12 {
  background-color: #fdd300;
  color: black;
  margin-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding: 5px;
}
.bkgrdcolor2.col.col-12 {
  color: red;
  margin-bottom: 10px;
  font-size: large;
  font-weight: bold;
  padding: 5px;
}
.actionBtn {
  height: 0px !important;
  min-width: 0px !important;
  padding: 0 !important;
}
</style>

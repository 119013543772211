<template>
  <v-card
    class="overflow-hidden"
    min-height="calc(100vh - 68px)"
    color="accent"
  >
    <v-overlay :value="displayProgress">
      <v-progress-circular
        :size="40"
        :width="4"
        color="amber"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <template>
      <b-breadcrumb :items="breadCrumbs" @click="stopInterval"></b-breadcrumb
    ></template>
    <v-banner single-line sticky dark>
      <v-row>
        <v-col cols="3">
          <BackActionBar @goback="goback"></BackActionBar>
        </v-col>
        <v-col cols="6">
          <ContextualActionBar
            title="Piano Operativo - Failover Group"
          ></ContextualActionBar>
        </v-col>
        <v-col cols="3" class="pr-3 pl-0 pt-6 text-right">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="managePlanActivation()"
              >
                <v-icon v-if="statusPlan == 'Attiva'">mdi-flash</v-icon>
                <v-icon v-else> mdi-close-box</v-icon>
              </v-btn>
            </template>
            <span v-if="statusPlan == 'Attiva'">ATTIVA PIANO</span>
            <span v-else>CHIUDI PIANO</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="toggleModalSelectPG(true)"
                ><v-icon>mdi-cloud-download-outline</v-icon>
              </v-btn>
            </template>
            <span>Esporta sistemi per Priority Group</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="planExport"
              >
                <v-icon>mdi-table-arrow-down</v-icon>
              </v-btn>
            </template>
            <span>ESPORTA PIANO</span>
          </v-tooltip>
          <v-tooltip bottom v-if="getActivation">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                rounded
                color="secondary"
                dark
                v-bind="attrs"
                v-on="on"
                class="ma-1 menuBtn"
                @click="updateDialogUpdatePlan(true)"
              >
                <!-- @click="openFileDialog" -->
                <v-icon>mdi-database-arrow-up</v-icon>
              </v-btn>
            </template>
            <span>CARICAMENTO MASSIVO SERVER,STATO</span>
          </v-tooltip>
          <v-btn
            v-else
            rounded
            color="secondary"
            dark
            disabled
            class="ma-1 menuBtn"
            @click="updateDialogUpdatePlan(true)"
          >
            <!-- @click="openFileDialog" -->
            <v-icon>mdi-database-arrow-up</v-icon>
          </v-btn>
          <TextReaderUpdate
            @load="textUpdate = $event"
            :disableAlert="disableAlert"
            :update="true"
          />
          <ModalUpdatePlan
            @open-dialog-update-plan="updateDialogUpdatePlan(false)"
            @open-file-dialog="openFileDialog"
            :updateInfo="openDialogUpdatePlan"
            :info="textUpdate"
          />
        </v-col>

        <v-col cols="12" class="d-flex flex-row justify-start align-center">
          <h6 v-if="this.getActivation">
            <span class="secondary--text">Inizio impatto</span>
            <em class="font-weight-regular">
              {{ impactSummaryValues.startOfImpact }}
            </em>
            <span class="secondary--text"> Tempo trascorso </span>
            <em class="font-weight-regular">
              {{ impactSummaryValues.timePassed }}
            </em>
          </h6>
        </v-col>

        <v-col cols="12" class="inline">
          <input
            v-model="serviceFilter"
            placeholder="Servizio"
            class="inputSearch"
          />
          <input v-model="nodeFilter" placeholder="Nodo" class="inputSearch" />
          <v-select
            placeholder="Priority Group"
            label="Priority Group"
            name="priorityGroup"
            id="priorityGroup"
            v-model="priorityGroup"
            :items="priorityGroups"
            @click="getPriorityGroups()"
            outlined
            dense
            hide-details
            class="limit-width"
          >
          </v-select>
          <v-select
            placeholder="FG Activation State"
            label="FG Activation State"
            name="activationState"
            id="activationState"
            v-model="activationState"
            :items="activationStates"
            outlined
            dense
            hide-details
            class="limit-width"
          >
          </v-select>
          <v-select
            placeholder="Service Activation State"
            label="Service Activation State"
            name="serviceActivationState"
            id="serviceActivationState"
            v-model="serviceActivationState"
            :items="serviceActivationStates"
            outlined
            dense
            hide-details
            class="limit-width"
          >
          </v-select>
          <v-btn
            rounded
            color="secondary"
            dark
            class="ma-1 btnSearch"
            @click="filter()"
          >
            Filtra
          </v-btn>
          <v-btn
            rounded
            color="secondary"
            dark
            class="ma-1 btnSearch"
            @click="updatedialogOrder(true)"
          >
            Ordina
          </v-btn>
          <v-btn
            rounded
            color="secondary"
            dark
            class="ma-1 btnSearch"
            @click="toggleModalSelectPG(true, 'edit')"
          >
            Modifica (PG)
          </v-btn>
        </v-col>
      </v-row>
    </v-banner>
    <v-sheet
      id="scrolling-techniques-7"
      class="overflow-y-auto pt-0"
      color="accent"
    >
      <perfect-scrollbar>
        <v-container fluid>
          <v-row>
            <v-col cols="12" class="pa-0" v-if="items.length > 0">
              <v-alert type="success" v-if="orderSuccessMsg">
                Ordinamento avvenuto con successo.
              </v-alert>
              <v-alert type="success" v-if="editSuccessMsg">
                Salvataggio avvenuto con successo.
              </v-alert>
              <v-alert type="error" v-if="errorMsg">
                Errore durante il salvataggio.
              </v-alert>
              <v-col
                v-for="item in items"
                :key="item.id + item.priorityGroup"
                cols="12"
                class="pb-0"
              >
                <FailoverGroup
                  :fgDataProp="item"
                  @stop-interval="stopInterval"
                  @api-error="showEditError"
                  @api-success="showEditSuccess"
                  @loading="toggleLoading"
                ></FailoverGroup>
              </v-col>
            </v-col>
            <v-col v-if="items.length == 0" class="textSerch">
              Nessun risultato trovato</v-col
            >
          </v-row>
        </v-container>
      </perfect-scrollbar>
      <template>
        <v-footer
          padless
          class="my-2"
          align="center"
          justify="center"
          color="accent"
        >
          <span class="white--text ml-4">FG per pagina</span>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn dark text color="secondary" v-bind="attrs" v-on="on">
                {{ itemsPerPage }}
                <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="(number, index) in itemsPerPageArray"
                :key="index"
                @click="updateItemsPerPage(number)"
              >
                <v-list-item-title>{{ number }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          <span class="white--text ml-4">Totale schede:</span
          ><span class="secondary--text ml-1"> {{ itemsTotal }}</span>

          <v-spacer></v-spacer>

          <span class="grey--text mr-4">
            Pagina {{ page }} di {{ numberOfPages }}
          </span>
          <v-btn fab x-small color="secondary" class="mr-1" @click="prevPage">
            <v-icon>mdi-chevron-left</v-icon>
          </v-btn>
          <v-btn
            fab
            x-small
            color="secondary"
            class="ml-1 mr-4"
            @click="nextPage"
          >
            <v-icon>mdi-chevron-right</v-icon>
          </v-btn>
        </v-footer>
      </template>
    </v-sheet>
    <ModalConfirm
      @open-dialog-confirm="updateDialogConfirm"
      :dialogConfirm="openDialogConfirm"
      :text="'Vuoi chiudere il piano?'"
      v-on:confirmed="closePlan()"
    ></ModalConfirm>
    <ModalSelectPG
      @modalSelectPGconfirm="modalSelectPGconfirm"
      @modalSelectPGcancel="modalSelectPGcancel"
      :modalSelectPGIsOpen="modalSelectPGIsOpen"
      :priorityGroups="priorityGroups"
    ></ModalSelectPG>
    <ModalOrder
      @open-dialog-order="updatedialogOrder"
      @confirm-order="confirmOrder"
      :dialogOrder="openDialogOrder"
      :items="this.allItems"
      :edit="this.lockId !== ''"
    ></ModalOrder>
    <ModalEditByPG
      :dialogEditByPGOpen="dialogEditByPGOpen"
      @edit-PG-confirm="editPGConfirm"
      @edit-PG-cancel="toggleDialogEditByPG(false)"
    ></ModalEditByPG>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import connectionMixin from "@/mixins/connectionParams.js";
import ContextualActionBar from "../components/core/ContextualActionBar.vue";
import FailoverGroup from "../components/core/FailoverGroup.vue";
import TextReaderUpdate from "../components/core/TextReaderUpdate.vue";
import ModalUpdatePlan from "../components/ModalUpdatePlan.vue";
import ModalConfirm from "@/components/ModalConfirm.vue";
import ModalSelectPG from "@/components/ModalSelectPG.vue";
import ModalEditByPG from "@/components/ModalEditByPG.vue";
import ModalOrder from "@/components/ModalOrder.vue";
import planIsActive from "@/mixins/planIsActive.js";
import download from "downloadjs";
import BackActionBar from "../components/core/BackActionBar.vue";

export default {
  name: "OperatingCards",
  components: {
    ContextualActionBar,
    FailoverGroup,
    TextReaderUpdate,
    ModalUpdatePlan,
    ModalOrder,
    ModalConfirm,
    BackActionBar,
    ModalSelectPG,
    ModalEditByPG,
  },
  mixins: [connectionMixin, planIsActive],
  data() {
    return {
      lockId: "",
      planId: "",
      items: [],
      allItems: [],
      textUpdate: {
        default: `Inserire nella prima colonna i nodi che si desidera aggiornare. Inserire nella seconda colonna lo stato per il relativo nodo.
                  Il delimitatore dei campi del file csv è il punto e virgola; la prima riga contiene gli header (non viene letta dal server).
                  Il file csv, per essere correttamente interpretato, deve essere formattato in UTF-8`,
      },
      disableAlert: false,
      displayProgress: true,
      lockLoading: false,
      impactSummaryValues: {},
      openDialogUpdatePlan: false,
      openDialogConfirm: false,
      serviceFilter: "",
      nodeFilter: "",
      breadCrumbs: [],
      page: 1,
      itemsPerPage: 12,
      startPage: 0,
      itemsTotal: 0,
      offset: 9,
      itemsPerPageArray: [12, 20, 52, 100],
      openDialogOrder: false,
      orderSuccessMsg: false,
      editSuccessMsg: false,
      errorMsg: false,
      priorityGroup: "",
      priorityGroups: [],
      activationState: "",
      activationStates: [],
      serviceActivationState: "",
      serviceActivationStates: [],
      modalSelectPGIsOpen: false,
      modalSelectPGMode: "",
      editPriorityGroup: "",
      dialogEditByPGOpen: false,
    };
  },
  mounted() {
    this.getUserProfile();
  },
  beforeDestroy() {
    this.releaseEditLock();
  },
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),
    statusPlan: function () {
      console.log("GET ACTIVATION");
      console.log(this.getActivation);
      return this.getActivation ? "Chiudi piano" : "Attiva";
    },
    numberOfPages() {
      return Math.ceil(this.itemsTotal / this.itemsPerPage);
    },
  },
  watch: {
    textUpdate: function (val) {
      var bodyData = {
        idPlan: this.planId,
        nodiRiavviati: val.nodiRiavviati,
      };
      this.$http
        //Axios post
        .post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_UPDATE_NODES_IN_PLAN"),
          bodyData,
          this.opts
        )
        .then((response) => {
          if (response) {
            var readed =
              "Lette " +
              response.data.readLines +
              " righe dal file caricato in input.";
            var updatedNips =
              "Aggiornati " + response.data.updatedNips + " nodi.";
            var errorCount = "";
            var nodeDisplayLabelNotFound = "";
            var esitoRiavvioNotFound = "";
            var nodeAlreadyCompleted = "";
            if (response.data.errorCount > 0) {
              errorCount =
                "Errori nella lettura del file: " +
                response.data.errorCount +
                ".";

              /* creo la riga del messaggio relativa a nodeDisplayLabelNotFound*/
              if (response.data.nodeDisplayLabelNotFound.length > 0) {
                response.data.nodeDisplayLabelNotFound.forEach((el, index) => {
                  if (
                    index ==
                    response.data.nodeDisplayLabelNotFound.length - 1
                  ) {
                    nodeDisplayLabelNotFound =
                      nodeDisplayLabelNotFound + el + ".";
                  } else {
                    nodeDisplayLabelNotFound =
                      nodeDisplayLabelNotFound + el + ",";
                  }
                });
                nodeDisplayLabelNotFound =
                  "Nodi non trovati: " + nodeDisplayLabelNotFound;
              }
              /* creo la riga del messaggio relativa a esitoRiavvioNotFound*/
              if (response.data.esitoRiavvioNotFound.length > 0) {
                response.data.esitoRiavvioNotFound.forEach((el, index) => {
                  if (index == response.data.esitoRiavvioNotFound.length - 1) {
                    esitoRiavvioNotFound = esitoRiavvioNotFound + el + ",";
                  } else {
                    esitoRiavvioNotFound = esitoRiavvioNotFound + el + ",";
                  }
                });
                esitoRiavvioNotFound =
                  "Esiti non validi: " + esitoRiavvioNotFound;
              }
              /* creo la riga del messaggio relativa a nodeAlreadyCompleted */
              if (response.data.nodeAlreadyCompleted.length > 0) {
                response.data.nodeAlreadyCompleted.forEach((el, index) => {
                  if (index == response.data.nodeAlreadyCompleted.length - 1) {
                    nodeAlreadyCompleted = nodeAlreadyCompleted + el + ",";
                  } else {
                    nodeAlreadyCompleted = nodeAlreadyCompleted + el + ",";
                  }
                });
                nodeAlreadyCompleted =
                  "Nodi già in stato completo: " + nodeAlreadyCompleted;
              }
            }
            /* creo il messaggio completo*/
            alert(
              readed +
                "\n" +
                updatedNips +
                "\n" +
                errorCount +
                "\n" +
                nodeDisplayLabelNotFound +
                "\n" +
                esitoRiavvioNotFound +
                "\n" +
                nodeAlreadyCompleted
            );
            this.disableAlert = true;
          }
          this.filtered();
          this.disableAlert = true;
        })
        .catch((error) => {
          this.disableAlert = true;
          console.log(error);
          window.location.reload();
        });
    },
  },
  methods: {
    ...mapActions("operationalModule", ["activate"]),
    onStart() {
      this.toggleLoading(true);
      this.lockLoading = true;

      this.checkStatusPlan();
      this.getActivePlan();
      if (this.$route.params.planId != undefined) {
        this.planId = this.$route.params.planId;

        this.getPriorityGroups();
        this.getActivationStates();
        this.getServiceActivationStates();

        this.filtered();
        this.getAllFailoverGroups();

        this.refreshDashboard();
      }

      if (this.getActivation) {
        this.impactSummaryValues = JSON.parse(localStorage.getItem("summary"));
      }
      if (this.getActivation) {
        this.breadCrumbs = [
          {
            text: "Home",
            to: "/",
          },
          {
            text: "Piano operativo",
            to: "/operationalplan",
          },
          {
            text: "Dashboard",
            to: "/opplanimpactandprogress/" + this.planId,
          },

          {
            text: "Failover Group",
            href: "#",
          },
        ];
      } else {
        this.breadCrumbs = [
          {
            text: "Home",
            to: "/",
          },
          {
            text: "Piano operativo",
            to: "/operationalplan",
          },
          {
            text: "Dashboard",
            to: "/opplanimpact/" + this.planId,
          },

          {
            text: "Failover Group",
            href: "#",
          },
        ];
      }
    },
    async lockEditResource() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/plan/" + this.planId,
          this.opts
        );
        console.log("lockEditResource", response.data);
        this.lockId = response.data.id;
        this.toggleLoading(false);
        return false;
      } catch (error) {
        console.log("error", error);
        if (error.response.status == 423) {
          alert(error?.response.data.username + " sta modificando la risorsa!");
        } else {
          alert("Si prega di ricaricare la pagina");
        }
        this.toggleLoading(false);
        return true;
      }
    },
    async releaseEditLock() {
      if (!this.lockId) {
        return;
      }
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/delete/" + this.lockId,
          this.opts
        );
        console.log("releaseEditLock", response.data);
        this.lockId = "";
        this.toggleLoading(false);
      } catch (error) {
        console.log("error", error);
        alert("Si prega di ricaricare la pagina");
        this.toggleLoading(false);
      }
    },
    async getUserProfile() {
      this.displayProgress = true;
      try {
        const response = await this.$http.get(
          this.$env.value("API_USER"),
          this.opts
        );
        if (response.data == "readonly") {
          this.$router.push("/");
        } else {
          this.onStart();
        }
        this.toggleLoading(false);
      } catch (error) {
        console.log(error);
        alert("Si prega di ricaricare la pagina");
        this.toggleLoading(false);
      }
    },
    showEditError() {
      this.errorMsg = true;
      setInterval(() => {
        this.errorMsg = false;
      }, 10000);
    },
    showEditSuccess() {
      this.editSuccessMsg = true;
      this.filtered();
      setInterval(() => {
        this.editSuccessMsg = false;
      }, 10000);
    },

    toggleLoading(value) {
      if (!this.lockLoading && this.displayProgress != value) {
        this.displayProgress = value;
        this.$forceUpdate();
      }
    },

    async openFileDialog() {
      const locked = await this.lockEditResource();
      if (!locked) {
        this.disableAlert = false;
        document.getElementById("text-reader").value = "";
        document.getElementById("text-reader").click();
      }
    },
    updateDialogUpdatePlan(value) {
      if (!value) {
        this.textUpdate = {
          default: `Inserire nella prima colonna i nodi che si desidera aggiornare. Inserire nella seconda colonna lo stato per il relativo nodo.
                    Il delimitatore dei campi del file csv è il punto e virgola; la prima riga contiene gli header (non viene letta dal server).
                    Il file csv, per essere correttamente interpretato, deve essere formattato in UTF-8`,
        };
      }
      this.openDialogUpdatePlan = value;
    },
    managePlanActivation() {
      if (!this.getActivation && this.activeplan.length > 0) {
        alert(
          "Esiste già un piano attivo. Chiuderlo prima di attivarne un altro"
        );
      } else {
        if (this.getActivation === false) {
          this.activePlan();
        } else {
          this.updateDialogConfirm(true);
        }
      }
    },
    updateDialogConfirm(value) {
      this.openDialogConfirm = value;
    },
    filter() {
      this.filtered();
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
      this.page = 1;
      this.startPage = 0;
      this.filtered();
    },
    async activePlan() {
      this.displayProgress = true;

      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_OPLAN_ACTIVE_PLAN") +
            "/" +
            this.planId,
          null,
          this.opts
        );
        console.log(response);
        this.activate(true);
        localStorage.setItem("planIsActive", true);
        this.breadCrumbs = [
          {
            text: "Home",
            to: "/",
          },
          {
            text: "Dashboard",
            to: "/opplanimpactandprogress/" + this.planId,
          },

          {
            text: "Failover Group",
            href: "#",
          },
        ];
        this.toggleLoading(false);
      } catch (error) {
        this.activate(false);
        this.toggleLoading(false);
        if (error.response.status == 409) {
          alert(
            "Il piano è stato già chiuso, non può essere attivato nuovamente"
          );
        } else {
          alert("Si prega di ricaricare la pagina");
        }
      }
    },
    async closePlan() {
      this.updateDialogConfirm(false);
      this.displayProgress = true;

      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_OPLAN_CLOSE_PLAN") +
            "/" +
            this.planId,
          null,
          this.opts
        );
        console.log(response);
        this.items = response.data.failovergroups;
        localStorage.setItem("planIsActive", false);
        this.toggleLoading(false);
        this.$router.push("/operationalplan");
      } catch (error) {
        this.activate(true);
        this.toggleLoading(false);
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    checkStatusPlan() {
      let isActive = localStorage.getItem("planIsActive");
      if (isActive == "false") {
        this.activate(false);
      } else {
        this.activate(true);
      }
    },
    goback() {
      this.stopInterval();
      if (this.getActivation) {
        this.$router.push("/opplanimpactandprogress/" + this.planId);
      } else {
        this.$router.push("/opplanimpact/" + this.planId);
      }
    },
    async exportSystemsByPG(priorityGroup) {
      this.displayProgress = true;
      try {
        const bodyData = {
          planId: this.planId,
          priorityGroup: priorityGroup,
        };
        const response = await this.$http.post(
          this.$env.value("API_HOST_REPORT") +
            this.$env.value("API_EXPORT_SYSTEM") +
            "/export-priorityGroup",
          bodyData,
          this.optsXlsPOST
        );
        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];
        download(response.data, "export-" + priorityGroup + ".xlsx", content);
        this.toggleLoading(false);
      } catch (error) {
        console.log(error);
        alert("Non è stato possibile esportare i dati. Riprovare più tardi");
        this.toggleLoading(false);
      }
    },
    async planExport() {
      this.displayProgress = true;

      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_REPORT") +
            this.$env.value("API_EXPORT_PLAN") +
            "/" +
            this.planId,
          this.optsXls
        );
        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];
        download(response.data, "export-piano.xlsx", content);
        this.toggleLoading(false);
      } catch (error) {
        console.log(error);
        alert("non è stato possibile esportare i dati. Riprovare più tardi");
        this.toggleLoading(false);
      }
    },
    async filtered() {
      var bodyData = {
        idPlan: this.planId,
        service: this.serviceFilter,
        node: this.nodeFilter,
        page: this.page - 1,
        size: this.itemsPerPage,
        env: this.selectedEnv,
        priorityGroup: this.priorityGroup,
        activationState: this.activationState,
        serviceState: this.serviceActivationState,
      };
      this.toggleLoading(true);
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_FILTERED") +
            "/paged",
          bodyData,
          this.opts
        );

        this.items = response.data.failovergroups;
        this.itemsTotal = response.data.numFailovergroups;
        this.toggleLoading(false);
        this.$forceUpdate();
      } catch (error) {
        this.toggleLoading(false);
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    modalSelectPGconfirm(priorityGroup) {
      this.toggleModalSelectPG(false);
      if (this.modalSelectPGMode === "edit") {
        // todo : edit modal here
        this.editPriorityGroup = priorityGroup;
        this.toggleDialogEditByPG(true);
      } else {
        this.exportSystemsByPG(priorityGroup);
      }
    },
    modalSelectPGcancel() {
      this.toggleModalSelectPG(false);
    },
    toggleDialogEditByPG(value) {
      this.dialogEditByPGOpen = value;
    },
    async toggleModalSelectPG(value, mode = "") {
      if (value === true) {
        this.modalSelectPGMode = mode;
        await this.getPriorityGroups();
      }
      this.modalSelectPGIsOpen = value;
    },
    async editPGConfirm(value) {
      const bodyData = {
        idPlan: this.planId,
        priorityGroup: this.editPriorityGroup,
        inseritaInPipeline: value.inseritaInPipeline || "",
        mailAppCompletaToCC1: value.mailAppCompletaToCC1 || "",
        mailRilascioCc1toAm: value.mailRilascioCc1toAm || "",
        feedbackAm: value.feedbackAm || "",
        dataVerificaAm: value.dataVerificaAm || "",
        note: value.note || "",
        restoreEffettuato: value.restoreEffettuato || "",
        infrStatus: value.infrStatus || "",
        finishDate: value.finishDate || "",
      };
      console.log(bodyData);

      this.dialogEditByPGOpen = false;
      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            "/massiveUpdateServicesInPlan",
          bodyData,
          this.opts
        );
        console.log(response);

        this.toggleLoading(false);
        window.location.reload();
      } catch (error) {
        this.toggleLoading(false);
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    async getPriorityGroups() {
      this.priorityGroups = [""];
      this.toggleLoading(true);
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            "/" +
            this.planId +
            "/" +
            "priorityGroups",
          this.opts
        );

        this.priorityGroups = response.data.values;
        this.priorityGroups.unshift("");
        this.toggleLoading(false);
      } catch (error) {
        this.toggleLoading(false);
        alert(
          "Non è stato possibile caricare i Priority Group per cui non sarà possibile utilizzare l'omonimo filtro."
        );
        console.log(error);
      }
    },
    async getActivationStates() {
      this.toggleLoading(true);
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            "/" +
            this.planId +
            "/" +
            "activationStates",
          this.opts
        );

        this.activationStates = response.data.values;
        this.activationStates.unshift("");
        this.toggleLoading(false);
      } catch (error) {
        this.toggleLoading(false);
        alert(
          "Non è stato possibile caricare gli Activation State per cui non sarà possibile utilizzare l'omonimo filtro."
        );
        console.log(error);
      }
    },
    async getServiceActivationStates() {
      this.toggleLoading(true);
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            "/" +
            this.planId +
            "/" +
            "serviceStates",
          this.opts
        );

        this.serviceActivationStates = response.data.values;
        this.serviceActivationStates.unshift("");
        this.toggleLoading(false);
      } catch (error) {
        this.toggleLoading(false);
        alert(
          "Non è stato possibile caricare i Service Activation State per cui non sarà possibile utilizzare l'omonimo filtro."
        );
        console.log(error);
      }
    },
    async getAllFailoverGroups() {
      this.toggleLoading(true);
      try {
        // {{store-api}}/plans/fg/{planId}/massiveOrderList
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") +
            this.$env.value("API_OPLANS") +
            this.$env.value("API_FG") +
            "/" +
            this.planId +
            this.$env.value("API_FG_ALL"),
          this.opts
        );

        this.allItems = response.data;

        this.lockLoading = false;
        this.toggleLoading(false);
      } catch (error) {
        this.lockLoading = false;
        this.toggleLoading(false);
        alert("Si prega di ricaricare la pagina");
        console.log(error);
      }
    },
    refreshDashboard() {
      this.interval = setInterval(() => {
        this.filtered();
        this.planId;
      }, 180000);
      setTimeout(() => {
        this.stopInterval();
        alert("Premere ok per visualizzare i dati aggiornati");
        location.reload();
      }, 1700000);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) {
        this.startPage += this.itemsPerPage;
        this.page += 1;
        this.filtered();
      }
    },
    prevPage() {
      if (this.page - 1 >= 1) {
        this.page -= 1;
        this.startPage -= this.itemsPerPage;
        this.filtered();
      }
    },
    stopInterval() {
      clearInterval(this.interval);
    },
    async updatedialogOrder(value) {
      if (value == true) {
        await this.getAllFailoverGroups();
        const locked = await this.lockEditResource();
        if (!locked) {
          this.openDialogOrder = true;
        }
      } else {
        this.releaseEditLock();
        this.openDialogOrder = false;
      }
    },
    confirmOrder(value) {
      this.update(value);
    },
    async update(value) {
      var bodyData = value;

      this.displayProgress = true;
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_SAVE_ORDER"),
          bodyData,
          this.opts
        );
        if (response.data);
        this.filtered();
        this.toggleLoading(false);
        this.orderSuccessMsg = true;
        setInterval(() => {
          this.orderSuccessMsg = false;
        }, 10000);
      } catch (error) {
        console.log(error);
        this.toggleLoading(false);
        this.errorMsg = true;
        setInterval(() => {
          this.errorMsg = false;
        }, 10000);
      }
    },
  },
};
</script>

<style scoped>
.ps {
  height: calc(100vh - 340px);
}
.hide {
  display: none !important;
}
.show {
  display: flex !important;
}
input.inputSearch {
  border-radius: 50px;
  background-color: white;
  padding: 5px 10px;
  margin-right: 5px;
  height: 100%;
}
.inline {
  display: inline-flex;
  align-items: center;
}
.limit-width {
  max-width: 200px;
  border-radius: 50px;
  margin-right: 5px;
}
.btnSearch {
  height: 100% !important;
}
.col.textSerch {
  text-align: center;
  color: white;
}
</style>

<template>
  <v-card>
    <v-card-subtitle class="white--text text-overline blue-grey pa-2">
      {{ fgData.priorityGroup }}&emsp;{{ fgData.name }}
    </v-card-subtitle>
    <v-card-text>
      <v-row>
        <v-col
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div class="text-caption text-uppercase font-weight-medium">
            Stato Attivazione
          </div>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :class="activationStateValues[0]"
                >mdi-square-rounded</v-icon
              >
            </template>
            <span>{{ activationStateValues[1] }}</span>
          </v-tooltip>
          <div
            class="text-caption text-uppercase text-center font-weight-medium"
          >
            {{ activationStateValues[1] }}
          </div>
        </v-col>
        <v-col
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Sistemi in fault
          </div>
          <span class="mx-2 font-weight-bold"
            >{{ fgData.numSystemFault }}/{{ fgData.numSystemTotal }}&nbsp;({{
              calculatePercentage(fgData.numSystemFault, fgData.numSystemTotal)
            }}%)
          </span>
        </v-col>
        <v-col
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Server NT in fault
          </div>
          <span
            v-if="fgData.numServerNtFault != 0 && fgData.numServerNtTotal != 0"
            class="mx-2 font-weight-bold"
            >{{ fgData.numServerNtFault }}/{{
              fgData.numServerNtTotal
            }}&nbsp;({{
              calculatePercentage(
                fgData.numServerNtFault,
                fgData.numServerNtTotal
              )
            }}%)</span
          >
          <span v-else class="mx-2 font-weight-bold"> 0%</span>
        </v-col>
        <v-col
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Server UNIX in fault
          </div>
          <span
            v-if="
              fgData.numServerUnixFault != 0 && fgData.numServerUnixTota != 0
            "
            class="mx-2 font-weight-bold"
            >{{ fgData.numServerUnixFault }}/{{
              fgData.numServerUnixTotal
            }}&nbsp;({{
              calculatePercentage(
                fgData.numServerUnixFault,
                fgData.numServerUnixTotal
              )
            }}%)</span
          >
          <span v-else class="mx-2 font-weight-bold"> 0%</span>
        </v-col>
        <v-col
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            Tempo stimato
          </div>
          <div class="mx-2 font-weight-bold">
            {{ fgData.estimatedTime }} giorni
          </div>
        </v-col>
        <v-col
          cols="2"
          class="mt-2 d-flex justify-start align-center flex-column flex-grow-0 flex-shrink-1"
        >
          <div
            class="text-caption text-uppercase font-weight-medium text-center"
          >
            RTO cumulativo
          </div>
          <div class="mx-2 font-weight-bold">
            {{ fgData.cumulativeRto }} giorni
          </div>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded icon v-bind="attrs" v-on="on" @click="openDetails">
            <v-icon color="primary">mdi-card-text-outline</v-icon>
          </v-btn>
        </template>
        <span>Apri dettaglio Failover Group</span>
      </v-tooltip>
      <!-- <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-bind="attrs"
            v-on="on"
            @click="openDetailsNewTab"
          >
            <v-icon color="primary">mdi-card-plus-outline</v-icon>
          </v-btn>
        </template>
        <span>Apri dettaglio Failover Group in Nuova Scheda</span>
      </v-tooltip> -->
      <v-tooltip bottom :class="statusPlan">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-bind="attrs"
            v-on="on"
            @click="exportImpactedSystems"
            :class="statusPlan"
          >
            <v-icon color="primary">mdi-download</v-icon>
          </v-btn>
        </template>
        <span>Esporta sistemi impattati</span>
      </v-tooltip>
      <v-tooltip bottom :class="statusPlan">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            icon
            v-bind="attrs"
            v-on="on"
            @click="toggleDialogEditServices(true)"
            :class="statusPlan"
          >
            <v-icon color="primary">mdi-pencil-outline</v-icon>
          </v-btn>
        </template>
        <span>Modifica servizi</span>
      </v-tooltip>
      <ModalEditServices
        @open-dialog-edit-services="toggleDialogEditServices"
        @confirm-edit-services="confirmEditServices"
        :dialogEditServicesIsOpen="dialogEditServicesIsOpen"
        :items="this.fgData.servicesInPlan || []"
        :planId="this.planId"
      ></ModalEditServices>

      <v-spacer></v-spacer>
      <span :class="statusPlan">
        <span class="text-caption text-uppercase font-weight-medium mr-2"
          >Avvio lavorazione:</span
        >
        <span class="text-body-2">{{ fgData.startDateProcessing }}</span>
      </span>
      &emsp;
      <span :class="statusPlan">
        <span class="text-caption text-uppercase font-weight-medium mr-2"
          >Tempo ripristino:</span
        >
        <span class="text-body-2">{{ fgData.recoveryTime }} </span>
      </span>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
import connectionMixin from "@/mixins/connectionParams.js";
import download from "downloadjs";
import ModalEditServices from "@/components/ModalEditServices.vue";
export default {
  components: { ModalEditServices },
  mixins: [connectionMixin],
  props: {
    fgDataProp: Object,
    // planId: {
    //   default: "",
    //   String,
    // },
  },
  beforeMount() {
    this.fgData = { ...this.fgDataProp };
  },
  beforeDestroy() {
    this.releaseEditLock();
  },
  data() {
    return {
      lockId: "",
      planId: this.$route.params.planId,
      activationStates: {
        completato: {
          color: "green--text",
          label: " Completato ",
        },
        nonAvviato: {
          color: "green--text",
          label: " Non Avviato ",
        },
        inLavorazione: {
          color: "blue--text",
          label: " In lavorazione ",
        },
        default: { color: "grey--text", label: " Non Avviato " },
      },
      dialogEditServicesIsOpen: false,
      openDialogPriority: false,
      fgData: {},
    };
  },
  watch: {
    fgDataProp: function (newVal) {
      this.fgData = newVal;
    },
  },
  computed: {
    ...mapGetters("operationalModule", ["getActivation"]),

    activationStateValues: function () {
      if (this.getActivation && this.fgData.activationState) {
        var colorstate =
          this.activationStates[
            this.parseActivationState(this.fgData.activationState)
          ].color;
        var labelState =
          this.activationStates[
            this.parseActivationState(this.fgData.activationState)
          ].label;
        return [colorstate, labelState];
      } else {
        return ["grey--text", "non avviato"];
      }
    },
    statusPlan: function () {
      return this.getActivation ? "d-block" : "d-none";
    },
  },
  methods: {
    async lockEditResource() {
      this.$emit("loading", true);
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/plan/" + this.planId,
          this.opts
        );
        console.log("lockEditResource", response.data);
        this.lockId = response.data.id;
        this.$emit("loading", false);
        return false;
      } catch (error) {
        console.log("error", error);
        if (error.response.status == 423) {
          alert(error?.response.data.username + " sta modificando la risorsa!");
        } else {
          alert("Si prega di ricaricare la pagina");
        }
        this.$emit("loading", false);
        return true;
      }
    },
    async releaseEditLock() {
      if (!this.lockId) {
        return;
      }
      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_STORE") + "/edit/delete/" + this.lockId,
          this.opts
        );
        console.log("releaseEditLock", response.data);
        this.lockId = "";
      } catch (error) {
        console.log("error", error);
        alert("Si prega di ricaricare la pagina");
      }
    },
    parseActivationState(val) {
      let activationStates;
      switch (val) {
        case "Completato":
          activationStates = "completato";
          break;
        case "In lavorazione":
          activationStates = "inLavorazione";
          break;
        case "Non Avviato":
          activationStates = "nonAvviato";
          break;
        default:
          activationStates = "default";
      }
      return activationStates;
    },
    calculatePercentage(num, tot) {
      return Math.round(100 * (num / tot));
    },
    openDetails() {
      this.$emit("stop-interval");
      localStorage.setItem("fgDataFGD", JSON.stringify(this.fgData));
      this.$router.push({
        name: "OpPlanFailoverGroupsDetails",
        params: {
          planId: this.planId,
          failoverGroupId: this.fgData.id,
        },
      });
    },
    openDetailsNewTab() {
      this.$emit("stop-interval");
      localStorage.setItem("fgDataFGD", JSON.stringify(this.fgData));
      let routeData = this.$router.resolve({
        name: "OpPlanFailoverGroupsDetails",
        params: {
          planId: this.planId,
          failoverGroupId: this.fgData.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
    async toggleDialogEditServices(value) {
      if (value == true) {
        const locked = await this.lockEditResource();
        if (!locked) {
          this.dialogEditServicesIsOpen = true;
        }
      } else {
        this.releaseEditLock();
        this.dialogEditServicesIsOpen = false;
      }
    },
    confirmEditServices(data) {
      console.log(data);
      this.updateServiceInPlan(data);
    },

    async updateServiceInPlan(bodyData) {
      this.$emit("loading", true);
      try {
        const response = await this.$http.post(
          this.$env.value("API_HOST") +
            this.$env.value("API_OPLAN") +
            this.$env.value("API_UPDATE_SERVICE_IN_PLAN"),
          bodyData.toApi,
          this.opts
        );
        console.log(response);
        this.$emit("loading", false);
        this.$emit("api-success");
        this.fgData.servicesInPlan = bodyData.editedTableValue;
      } catch (error) {
        console.log(error);
        this.$emit("loading", false);
        this.$emit("api-error");
      }
      localStorage.setItem("fgDataFGD", JSON.stringify(this.fgData));
    },
    updatePriorityValue(id, priority, reload) {
      this.$emit("reload-failover-group", id, priority, reload);
    },
    async exportImpactedSystems() {
      this.$emit("loading", true);

      try {
        const response = await this.$http.get(
          this.$env.value("API_HOST_REPORT") +
            this.$env.value("API_EXPORT_SYSTEM") +
            "/" +
            this.planId +
            "/" +
            this.fgData.id,
          this.optsXls
        );

        const content =
          response.headers[
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          ];
        download(response.data, this.fgData.name + ".xlsx", content);
        this.$emit("loading", false);
      } catch (error) {
        console.log(error);
        alert("non è stato possibile esportare i dati. Riprovare più tardi");
        this.$emit("loading", false);
      }
    },
  },
};
</script>

<style></style>
